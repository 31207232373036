
import { BASE_API_URL } from '@/utils/Constants';
import { Component, Vue } from 'vue-property-decorator';

const BASE_CARRIER_STATUS_URL = BASE_API_URL+"carrier_statuses"

@Component
export default class CarrierStatusesMixin extends Vue {

  getCarrierStatuses(data: any, tag: string) {
    return this.$httpGetQuery(BASE_CARRIER_STATUS_URL, tag,data);
  }

  destroyCarrierStatus(id: string|number, tag: string) {
    return this.$httpDelete(BASE_CARRIER_STATUS_URL+"/"+id, tag);
  }

  createCarrierStatus(data: any, tag: string) {
    return this.$httpPost(BASE_CARRIER_STATUS_URL, tag, data);
  }

  updateCarrierStatus(id: string, data: any, tag: string) {
    return this.$httpPut(BASE_CARRIER_STATUS_URL+"/"+id, tag, data);
  }
}
