





































import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import VModal from "@/components/VModal.vue";
import CarrierStatusesMixin from "@/mixins/http/CarrierStatusesMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import { namespace } from "vuex-class";
import { Source } from "@/models";
import CarrierSelect from "@/components/Carrier/Select.vue";
import { showToast } from "@/utils/Toast";
import { NoCache } from "@/utils/Decorators";
import { CarrierStatus } from "@/models";
import MatchItem from "@/components/CarrierStatus/MatchItem.vue";
import { Match } from "@/models/CarrierStatus";
import { ShippingContract } from "@/models";
import { easync } from "@/utils/http";
const createRequestTag = "create_carrier_status_tag";

@Component({
  components: {
    VModal,
    CarrierSelect,
    MatchItem,
  },
})
export default class CarrierStatusCreateModal extends Mixins(
  CarrierStatusesMixin
) {
  @RegisterHttp(createRequestTag) createRequest: RequestData;
  modal!: VModal;
  content = "";

  item: CarrierStatus = null;

  canceled!: any | null;

  mounted() {
    this.modal = this.$refs.modal as VModal;
  }

  removeMatch(index: number) {
    this.item.matches.splice(index, 1);
  }

  loading() {
    return this.createRequest?.loading;
  }

  onCarrierStatusSelected(carrier: any) {
    this.item.carrier = carrier.code;
  }

  get carrier() {
    return ShippingContract.carriers.find(o => o.code === this.item.carrier)?.id;
  }

  get valid() {
    return this.item?.name && this.item?.carrier
  }

  title() {
    return `Nuovo stato corriere`;
  }

  get hasContent(): boolean {
    return this.$slots.default != null;
  }

  protected onCancel() {
    if (this.canceled) {
      this.canceled();
    }

    this.canceled = null;
    this.modal.hide();
    this.reset();
  }

  edit(activity: CarrierStatus) {
    this.item = activity;
    this.modal.show();
  }

  onAddMatch() {
    this.item.matches.push(Match.empty());
  }

  create() {
    this.item = CarrierStatus.empty();
    this.modal.show();
  }

  save() {
    this.item.id ? this.updateStatus() : this.createStatus();
  }

  async createStatus() {
    const [data, errors] = await easync(
      this.createCarrierStatus({...this.item },createRequestTag)
    );

    if (errors) {
      showToast("Errore durante l'aggiornamento'", { type: "error" });
      return;
    }

    this.$emit("success", data);
    this.modal.hide();
    showToast("Stato corriere creato!");
    this.reset();
  }

  async updateStatus() {
    const [data, errors] = await easync(
      this.updateCarrierStatus(this.item.id, { ...this.item }, createRequestTag)
    );

    if (errors) {
      showToast("Errore durante l'aggiornamento'", { type: "error" });
      return;
    }

    this.$emit("update", data);
    this.modal.hide();
    showToast("Stato corriere creato!");
    this.reset();
  }

  protected onConfirm() {
    this.save();
  }

  protected onHidden() {
    if (this.canceled) {
      this.canceled();
    }
  }

  reset() {
    this.item = new CarrierStatus(undefined);
  }

  show() {
    this.modal.show();
  }
}
