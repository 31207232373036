




























import { Match } from "@/models/CarrierStatus";
import { Vue, Component, Prop } from "vue-property-decorator";
import ScopeSelect from "@/components/CarrierStatus/ScopeSelect.vue";
import ShipmentStatusSelect from "@/components/Shipment/StatusSelect.vue";

@Component({ components: { ScopeSelect, ShipmentStatusSelect } })
export default class MatchItem extends Vue {
  @Prop({ required: true }) readonly match!: Match;

  private item: Match = this.match;

  onScopeSelected(scope: "tracking" | "shipment") {
    this.item.scope = scope;
  }

  onShipmentStatusSelected(status: string) {
    this.item.shipmentStatus = status;
  }

  removeMatch() {
    this.$emit('destroy')
  }
}
